import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useMenuContext, actions } from '@/context/MenuContext'
import { useSetStatusCode } from '@/components/request-logging'

const ThrowStatusCode = ({ statusCode, ErrorComponent, ...props }) => {
  const router = useRouter()
  const [, dispatch] = useMenuContext()

  const setStatusCode = useSetStatusCode()
  setStatusCode(statusCode)

  useEffect(() => {
    dispatch({
      type: actions.SET_HAS_BACKGROUND_COLOR,
      payload: true,
    })

    return () => {
      dispatch({
        type: actions.SET_HAS_BACKGROUND_COLOR,
        payload: false,
      })
    }
  }, [dispatch])

  if (typeof window === 'undefined') {
    const e = new Error()
    e.name = statusCode
    e.statusCode = statusCode
    e.message = `${statusCode}: ${router?.asPath}`
    throw e
  }

  return <ErrorComponent {...props} />
}

ThrowStatusCode.propTypes = {
  statusCode: PropTypes.number.isRequired,
  ErrorComponent: PropTypes.func.isRequired,
}

export default ThrowStatusCode
