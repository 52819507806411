import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import messages from '@/messages/payment'
import {
  ErrorState,
  PaymentErrorTypes,
} from '@/context/PaymentErrorsContext/types'

const usePaymentErrors = () => {
  const intl = useIntl()
  const [error, setError] = useState<ErrorState | null>(null)

  const errorMessages = {
    [PaymentErrorTypes.SomethingWentWrong]: {
      title: intl.formatMessage(messages.smthWentWrong),
      message: intl.formatMessage(messages.smthWentWrongText),
    },
    [PaymentErrorTypes.PaymentFailed]: {
      title: intl.formatMessage(messages.paymentFailed),
      message: intl.formatMessage(messages.paymentFailedText),
    },
    [PaymentErrorTypes.TicketsSoldOut]: {
      title: intl.formatMessage(messages.ticketsNotAvailable),
      message: intl.formatMessage(messages.ticketsNotAvailableText),
    },
    [PaymentErrorTypes.SessionExpired]: {
      title: intl.formatMessage(messages.sessionExpired),
    },
  }

  const setErrorCallback = useCallback(
    (type: PaymentErrorTypes, message?: string) => {
      setError({ type, message })
    },
    []
  )

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  return {
    type: error?.type,
    error: error
      ? {
          ...errorMessages[error.type],
          ...(error.message ? { message: error.message } : {}),
        }
      : null,

    setError: setErrorCallback,

    clearError,
  }
}

export default usePaymentErrors
