import { createContext, FC, ReactNode, useContext } from 'react'
import { PaymentErrorTypes } from '@/context/PaymentErrorsContext/types'
import usePaymentErrors from '@/context/PaymentErrorsContext/usePaymentErrors'

export type PaymentErrorContextType = {
  type?: PaymentErrorTypes
  error: { title: string; message?: string } | null
  setError: (type: PaymentErrorTypes, message?: string) => void
  clearError: () => void
}

const PaymentErrorContext = createContext<PaymentErrorContextType>({
  error: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError: () => {},
})

const PaymentErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { type, error, setError, clearError } = usePaymentErrors()

  return (
    <PaymentErrorContext.Provider value={{ type, error, setError, clearError }}>
      {children}
    </PaymentErrorContext.Provider>
  )
}

export const usePaymentErrorContext = () => {
  const context = useContext(PaymentErrorContext)

  if (!context) {
    throw new Error(
      'usePaymentErrorContext must be used within a PaymentErrorProvider'
    )
  }

  return context
}

export default PaymentErrorProvider
