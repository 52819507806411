import gql from 'graphql-tag'

const CREATE_ORDER = gql`
  mutation Ticketing_createOrder(
    $basketId: UUID!
    $paymentDetails: Ticketing_PaymentDetailsInput
    $source: Ticketing_OrderSourceType
  ) {
    Ticketing_createOrder(
      data: {
        basketId: $basketId
        paymentDetails: $paymentDetails
        source: $source
      }
    ) {
      id
      orderNumber
    }
  }
`

export default CREATE_ORDER
