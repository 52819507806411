import { useCallback, useState } from 'react'
import canUseDOM from 'lib/canUseDom'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'

// local storage hook adapted from https://usehooks.com/useLocalStorage/
const dateReviver = (_, value) => {
  // using a reviver function to parse date strings back into date objects - https://gist.github.com/jcbozonier/1692807
  if (typeof value === 'string') {
    const a =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value)

    if (a) {
      return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]))
    }
  }
  return value
}

const useLocalStorage = (key, initialValue) => {
  const [cleared, setCleared] = useState(false)
  const clearValue = useCallback(() => {
    if (canUseDOM() && window?.localStorage?.removeItem) {
      window.localStorage.removeItem(key)
      window.localStorage.removeItem(`${key}-last-updated`)
    }
  }, [key])

  const [localValue, setLocalValue] = useState(() => {
    if (canUseDOM() && window?.localStorage?.getItem) {
      const value = window.localStorage.getItem(key)
      const lastUpdatedValue = window.localStorage.getItem(
        `${key}-last-updated`
      )
      const localStorageLastUpdated = JSON.parse(
        lastUpdatedValue,
        dateReviver
      )?.lastUpdated

      if (localStorageLastUpdated) {
        const expiryDate = addDays(localStorageLastUpdated, 1)
        if (isPast(expiryDate)) {
          clearValue()
          setCleared(true)
        }
      }
      return value ? JSON.parse(value, dateReviver) : initialValue
    }
    return initialValue
  })

  const setLocalStorageValue = (value) => {
    setCleared(false)
    if (canUseDOM() && window?.localStorage?.setItem) {
      setLocalValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
      window.localStorage.setItem(
        `${key}-last-updated`,
        JSON.stringify({ lastUpdated: new Date() })
      )
    }
  }

  return {
    localValue,
    setLocalStorageValue,
    clearValue,
    cleared,
  }
}

export { dateReviver }
export default useLocalStorage
