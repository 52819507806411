export type ErrorState = {
  type: PaymentErrorTypes
  message?: string
}

export enum PaymentErrorTypes {
  SomethingWentWrong = 'SomethingWentWrong',
  PaymentFailed = 'PaymentFailed',
  TicketsSoldOut = 'TicketsSoldOut',
  SessionExpired = 'SessionExpired',
  FreeTicketOrderError = 'FreeTicketOrderError',
}
