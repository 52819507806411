import { gql } from '@apollo/client'

const GET_BASKET = gql`
  query Ticketing_basket($where: Ticketing_BasketWhereUniqueInput!) {
    Ticketing_basket(where: $where) {
      id
      expiresAt
      total
      currency {
        code
      }
      basketItems {
        id
        quantity
        #TOFIX: Have to resolve the ticketPromoId to get promo price
        ticketPromoId
        ticketCost {
          displayPrice
          displayFee
          displayPromoPrice
          displayPromoFee
          type
        }
        ticketTier {
          id
          title
          currency {
            id
            code
          }
          event {
            id
            legacyId
          }
        }
      }
    }
  }
`

export default GET_BASKET
