/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'Payment.Title',
    defaultMessage: 'Payment',
  },
  showSummary: {
    id: 'Payment.ShowSummary',
    defaultMessage: 'Show order summary',
  },
  hideSummary: {
    id: 'Payment.HideSummary',
    defaultMessage: 'Hide order summary',
  },
  orderSummary: {
    id: 'Payment.OrderSummary',
    defaultMessage: 'Order summary',
  },
  agreement: {
    id: 'Payment.Agreement',
    defaultMessage: 'By continuing you agree to our',
  },
  addPaymentCard: {
    id: 'Payment.AddPaymentCard',
    defaultMessage: 'Add payment card',
  },
  nameOnCard: {
    id: 'Payment.NameOnCard',
    defaultMessage: 'Name on card',
  },
  cardNumber: {
    id: 'Payment.CardNumber',
    defaultMessage: 'Card number',
  },
  expiryDate: {
    id: 'Payment.ExpiryDate',
    defaultMessage: 'Expiry date',
  },
  securityCode: {
    id: 'Payment.SecurityCode',
    defaultMessage: 'Security code',
  },
  postcode: {
    id: 'Payment.Postcode',
    defaultMessage: 'Postcode',
  },
  saveCard: {
    id: 'Payment.SaveCard',
    defaultMessage: 'Save card',
  },
  anotherCard: {
    id: 'Payment.AnotherCard',
    defaultMessage: 'Pay with another card',
  },
  cardNumberError: {
    id: 'Payment.CardNumberError',
    defaultMessage: 'Invalid card number',
  },
  cvvShortError: {
    id: 'Payment.cvvShortError',
    defaultMessage: 'Invalid security code',
  },
  cvvNeed4Error: {
    id: 'Payment.cvvNeed4Error',
    defaultMessage: '4 digits required',
  },
  expirationDateError: {
    id: 'Payment.ExpirationDateError',
    defaultMessage: 'Invalid expiration date',
  },

  smthWentWrong: {
    id: 'Payment.SomethingWentWrong',
    defaultMessage: 'Something went wrong',
  },

  smthWentWrongText: {
    id: 'Payment.SomethingWentWrongText',
    defaultMessage: 'Please try again.',
  },

  paymentFailed: {
    id: 'Payment.PaymentFailed',
    defaultMessage: 'Payment failed',
  },

  paymentFailedText: {
    id: 'Payment.PaymentFailedText',
    defaultMessage:
      'We are unable to complete your payment right now. Please retry or try another payment method.',
  },

  ticketsNotAvailable: {
    id: 'Payment.TicketsNotAvailable',
    defaultMessage: 'Tickets no longer available',
  },

  ticketsNotAvailableText: {
    id: 'Payment.TicketsNotAvailableText',
    defaultMessage: 'Please go back and try again.',
  },

  sessionExpired: {
    id: 'Payment.SessionExpired',
    defaultMessage: 'Your session has expired',
  },

  sessionExpiredButton: {
    id: 'Payment.SessionExpiredButton',
    defaultMessage: 'Try again',
  },

  failedLoadComcardeSDK: {
    id: 'Payment.FailedLoadComcardeSDK',
    defaultMessage: 'Failed to load comcarde SDK',
  },

  failedCreateComcardeClient: {
    id: 'Payment.failedCreateComcardeClient',
    defaultMessage: 'Failed to create comcarde client',
  },

  failedCreateHostedFields: {
    id: 'Payment.failedCreateHostedFields',
    defaultMessage: 'Failed to create hosted fields',
  },

  failedPostActionLoad: {
    id: 'Payment.failedPostActionLoad',
    defaultMessage: 'Failed to load post action',
  },

  ticketsOnHold: {
    id: 'Payment.TicketsOnHold',
    defaultMessage: 'Tickets on hold',
  },

  youGotTickets: {
    id: 'Payment.YouGotTickets',
    defaultMessage: '{name}, you got the tickets!',
  },
  confirmationSentToEmail: {
    id: 'Payment.ConfirmationSentToEmail',
    defaultMessage:
      'A ticket confirmation has been sent to <bold>{email}</bold>',
  },

  viewTickets: {
    id: 'Payment.ViewTickets',
    defaultMessage: 'View tickets on the',
  },

  whyDownloadRAGuide: {
    id: 'Payment.WhyDoINeedToDownloadTheRAGuide',
    defaultMessage: 'Why do I need to download the RA Guide?',
  },

  downloadReason1: {
    id: 'Payment.DownloadReason1',
    defaultMessage:
      'Adding your tickets to the app stops ticket touts from selling tickets over the market price',
  },
  downloadReason2: {
    id: 'Payment.DownloadReason2',
    defaultMessage: 'Easily access your tickets 24 hours before the event',
  },

  learnHowRaSupportElectronic: {
    id: 'Payment.LearnHowRaSupportElectronic',
    defaultMessage:
      'Learn how RA supports the electronic <break></break> music community',
  },

  downloadRaGuide: {
    id: 'Payment.DownloadRaGuide',
    defaultMessage: 'Download RA Guide app',
  },

  viewOrderTickets: {
    id: 'Payment.ViewOrderTickets',
    defaultMessage: 'View tickets in RA Guide',
  },
  cvvTooltip: {
    id: 'Payment.CVVTooltip',
    defaultMessage:
      'Usually the last 3 digits on the back of the card or 4 digits on the front for American Express cards.',
  },
  paymentPageTitle: {
    id: 'Payment.PaymentPageTitle',
    defaultMessage: 'Payment ⟋ RA',
  },
  continuingTermsAndConditionsNew: {
    id: 'Payment.ContinuingTermsAndConditionsNew',
    defaultMessage:
      'By continuing, you are indicating that you have read and agree to the <terms>Terms of Use</terms>, <purchase>Purchase Policy</purchase> and <privacy>Privacy Policy</privacy>.',
  },
  areYouRegistered: {
    id: 'Payment.AreYouRegistered',
    defaultMessage: "Not sure if you've already registered with us?",
  },
  emailCheck: {
    id: 'Payment.EmailCheck',
    defaultMessage:
      "Don't worry, we can tell from your email address whether you already have an RA account.",
  },
  freeTicketLimit: {
    id: 'Payment.FreeTicketLimit',
    defaultMessage: 'Free ticket limit',
  },
  freeTicketLimitText: {
    id: 'Payment.FreeTicketLimitText',
    defaultMessage: 'Only one ticket can be ordered per customer.',
  },
  promoCodeApplied: {
    id: 'Payment.PromoCodeApplied',
    defaultMessage: '{promoCode} applied',
  },
})

export default messages
