import { PropsWithChildren } from 'react'
import getConfig from 'next/config'
import styled from 'styled-components'
import { Box } from '@resident-advisor/design-system'
import ENVIRONMENT from '@/enums/environment'
import Footer from '@/components/shared/footer'
import CornerNav from '@/components/navigation/corner-nav'
import GlobalNav from '@/components/navigation/global-nav'
import StickyAd from '@/components/generic/sticky-ad'
import UATBanner from '@/components/generic/uat-banner'
import CookieConsentBanner from '@/components/shared/cookie-consent-banner/CookieConsentBanner'
import PageBeaconWrapper from '@/components/generic/helpscout/PageBeaconWrapper'
import MobileNavMenu from '@/components/navigation/mobile-nav/MobileNavMenu'

const { publicRuntimeConfig } = getConfig()

const isUAT = publicRuntimeConfig.ENVIRONMENT === ENVIRONMENT.staging

export type DefaultLayoutSettings = {
  hideLeader?: boolean
  showMobileGlobalNav?: boolean
  hideCookieConsentBanner?: boolean
  hideCornerNav?: boolean
  hideFooterMobile?: boolean
  footerBottomPadding?: number
}

const DefaultLayout =
  (
    WrappedComponent,
    layoutSettings: DefaultLayoutSettings = {
      hideLeader: false,
      showMobileGlobalNav: false,
      hideCookieConsentBanner: false,
      hideCornerNav: false,
      hideFooterMobile: false,
      footerBottomPadding: 0,
    }
  ) =>
  (props) => {
    return (
      <DefaultLayoutMarkup
        WrappedComponent={WrappedComponent}
        componentProps={props}
        {...layoutSettings}
      />
    )
  }

type DefaultLayoutMarkupSettings = {
  WrappedComponent: React.ComponentType
  componentProps: object
} & DefaultLayoutSettings

const DefaultLayoutMarkup = ({
  WrappedComponent,
  componentProps,
  hideCookieConsentBanner,
  hideLeader,
  showMobileGlobalNav,
  hideFooterMobile,
  hideCornerNav,
  footerBottomPadding,
}: DefaultLayoutMarkupSettings) => (
  <PageBeaconWrapper>
    {!hideCookieConsentBanner && <CookieConsentBanner />}
    <LayoutContainer>
      {isUAT && <UATBanner />}
      {!hideLeader && <StickyAd />}
      <GlobalNav showMobileGlobalNav={showMobileGlobalNav} />
      <WrappedComponent {...componentProps} />
    </LayoutContainer>
    <Box
      display={{ s: hideFooterMobile ? 'none' : 'block', m: 'block' }}
      pb={{ m: footerBottomPadding }}
    >
      <Footer />
    </Box>
    {!hideCornerNav && <CornerNav />}
    <MobileNavMenu />
  </PageBeaconWrapper>
)

/*
 We use this container in order to ensure that the footer is always at the bottom
 of the fold (except for some exceptional cases where the page height is larger
 than expected) so that it will not negatively affect CLS
 */
const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  min-height: 90hv;
  flex-direction: column;
`
const LayoutContainer = ({ children }: PropsWithChildren<object>) => (
  <Container className="layoutContainer" data-testid="layoutContainer">
    {children}
  </Container>
)

export default DefaultLayout
export { DefaultLayoutMarkup }
