import { defineMessages } from 'react-intl'

const messages = defineMessages({
  'pre.configured.title.general.admission': {
    id: 'Pro.GeneralAdmission',
    defaultMessage: 'General admission',
  },
  'pre.configured.title.early.bird': {
    id: 'Pro.EarlyBird',
    defaultMessage: 'Early bird',
  },
  'pre.configured.title.first.release': {
    id: 'Pro.FirstRelease',
    defaultMessage: 'First release',
  },
  'pre.configured.title.second.release': {
    id: 'Pro.SecondRelease',
    defaultMessage: 'Second release',
  },
  'pre.configured.title.third.release': {
    id: 'Pro.ThirdRelease',
    defaultMessage: 'Third release',
  },
  'pre.configured.title.final.release': {
    id: 'Pro.FinalRelease',
    defaultMessage: 'Final release',
  },
  'pre.configured.title.advance.ticket': {
    id: 'Pro.AdvanceTicket',
    defaultMessage: 'Advance ticket',
  },
  'pre.configured.title.custom.name': {
    id: 'Pro.CustomName',
    defaultMessage: 'Create a custom ticket name',
  },
})

export default messages
