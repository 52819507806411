import { useMutation } from '@apollo/client'
import { ComcardeTokenizePayload } from '@/interfaces/Comcarde'
import TicketCostType from '@/enums/ticketing-service/ticket-cost-type'
import OrderSourceType from '@/enums/ticketing-service/OrderSourceType'
import CREATE_ORDER from '../../gql/CreateOrderMutation'
import { NewMethodFormValues } from '../../types'

const useCreateOrder = (formValues?: NewMethodFormValues) => {
  const [createOrder, { loading }] = useMutation(CREATE_ORDER)

  const handleCreateOrder = async (
    basketId: string,
    ticketCostType: TicketCostType,
    payload?: ComcardeTokenizePayload
  ) => {
    try {
      if (ticketCostType === TicketCostType.Free) {
        return createOrder({
          variables: {
            basketId,
            source: OrderSourceType.web,
          },
        })
      }

      return createOrder({
        variables: {
          basketId,
          paymentDetails: {
            postcode: formValues.postcode,
            token: payload.token,
          },
          source: OrderSourceType.web,
        },
      })
    } catch (err) {
      // TODO implement 3D Secure after BE implementation
      // const response = await postResponseActionRef.current?.handleAction(res)
      //
      // const { message, paymentId } = response // text explanation of the outcome
      //
      // if (response.code === 2002) {
      //   // can be used to progress the payment via your server
      //   const { nonce } = response
      //
      //   // Optional additional information to help you make an informed decision
      //   // whether to proceed with the payment
      //   if (response.threeDSecureInfo) {
      //     // status description returned by the PSP
      //     const { pspStatus } = response.threeDSecureInfo
      //
      //     // e.g. "2"
      //     const { threeDSecureVersion } = response.threeDSecureInfo
      //
      //     if (response.threeDSecureInfo.liabilityShifted) {
      //       /*
      //        * 3-D Secure worked and authentication succeeded.
      //        *
      //        * This will also be true if the issuing bank does not support 3-D
      //        * Secure, but the payment method does. In both cases, the liability
      //        * for fraud has been shifted to the bank. You can now finalize the
      //        * payment using response.nonce on your server.
      //        */
      //     } else {
      //       /*
      //        * 3-D Secure authentication failed. It is still possible to finalize
      //        * the payment using the payment.nonce via your server, but you will
      //        * need to set 3-D secure required flag to false.
      //        */
      //
      //       if (response.threeDSecureInfo.liabilityShiftPossible) {
      //         // the payment instrument was eligible for 3-D Secure
      //       } else {
      //         // the payment instrument was not eligible for 3-D secure.
      //       }
      //     }
      //   }
      // } else if (response.code === 2004) {
      //   // an error occurred when attempting 3-D Secure authentication.
      //   // See response.message
      // }

      throw err
    }
  }
  return { loading, handleCreateOrder }
}

export default useCreateOrder
