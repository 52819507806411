import { gql } from '@apollo/client'

const GET_ORDER_FULFILMENT_STATUS = gql`
  query GET_ORDER_FULFILMENT_STATUS($where: Ticketing_OrderWhereUniqueInput!) {
    Ticketing_order(where: $where) {
      id
      fulfilmentStatus
    }
  }
`

export default GET_ORDER_FULFILMENT_STATUS
