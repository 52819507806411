import { useEffect, useState } from 'react'
import getConfig from 'next/config'
import scrollToTop from 'lib/scrollToTop'
import { useRouter } from 'next/router'
import { PaymentErrorTypes } from '@/context/PaymentErrorsContext/types'
import { usePaymentErrorContext } from '@/context/PaymentErrorsContext/PaymentErrorsContext'
import FulfilmentStatus from '@/enums/ticketing-service/fulfilment-status'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useQueryResult } from '@/components/generic/query-result-handler'
import GET_ORDER_FULFILMENT_STATUS from '../../GetOrderFulfilmentStatus'

const usePollForFulfilmentStatus = async (
  eventId: string,
  orderNumber: string,
  setIsProcessing: (isProcessing: boolean) => void
) => {
  const router = useRouter()
  const { setError } = usePaymentErrorContext()
  const { clearValue } = useLocalStorage('basketId', undefined)

  const [confirmedOrderNumber, setConfirmedOrderNumber] = useState('')

  useEffect(() => {
    setConfirmedOrderNumber(orderNumber)
  }, [orderNumber])
  const {
    data: pollingData,
    startPolling,
    stopPolling,
  } = useQueryResult(GET_ORDER_FULFILMENT_STATUS, {
    skip: !confirmedOrderNumber,
    variables: { where: { orderNumber: confirmedOrderNumber } },
    notifyOnNetworkStatusChange: true,
    dataKey: 'Ticketing_order',
  })

  const fulfilmentStatus = pollingData?.fulfilmentStatus

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (confirmedOrderNumber) {
      setIsProcessing(true)
      startPolling(PAYMENT_PROCESSING.POLLING_INTERVAL)

      timeout = setTimeout(() => {
        stopPolling()
        setIsProcessing(false)
        setError(PaymentErrorTypes.PaymentFailed)
      }, PAYMENT_PROCESSING.POLLING_TIMEOUT)
    }
    return () => {
      stopPolling()
      clearTimeout(timeout)
    }
  }, [
    confirmedOrderNumber,
    setError,
    setIsProcessing,
    startPolling,
    stopPolling,
  ])

  useEffect(() => {
    if (
      fulfilmentStatus === FulfilmentStatus.Completed ||
      fulfilmentStatus === FulfilmentStatus.Failed
    ) {
      setIsProcessing(false)
      stopPolling()

      if (fulfilmentStatus === FulfilmentStatus.Completed) {
        clearValue()
        router.push(`/events/${eventId}/?orderNumber=${confirmedOrderNumber}`)
        setConfirmedOrderNumber('')
      } else if (
        fulfilmentStatus === FulfilmentStatus.Failed &&
        confirmedOrderNumber
      ) {
        setError(PaymentErrorTypes.PaymentFailed)
        scrollToTop()
      }
    }
  }, [
    confirmedOrderNumber,
    eventId,
    fulfilmentStatus,
    router,
    clearValue,
    setError,
    stopPolling,
    setIsProcessing,
  ])
}
const {
  publicRuntimeConfig: { PAYMENT_PROCESSING },
} = getConfig()
export default usePollForFulfilmentStatus
