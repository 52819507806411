import regex from 'lib/regex'
import { useIntl } from 'react-intl'

const useNormalizedCurrency = (num, currencyCode, { shorten = true } = {}) => {
  const intl = useIntl()
  const fractionDigits = num >= 1000 || num === 0 ? 1 : 2

  if (!currencyCode) {
    return intl.formatNumber(num)
  }

  const shorteningFormatOptions = shorten
    ? {
        roundingType: 'fractionDigits',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        notation: 'compact' as
          | 'standard'
          | 'scientific'
          | 'engineering'
          | 'compact',
      }
    : {}

  const formatOptions = {
    style: 'currency' as 'currency' | 'unit' | 'decimal' | 'percent',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol' as
      | 'symbol'
      | 'code'
      | 'name'
      | 'narrowSymbol',
    ...shorteningFormatOptions,
  }

  const formattedCurrencyString = String(intl.formatNumber(num, formatOptions))
  return shorten
    ? formattedCurrencyString.replace(regex.removeTrailingZeroes, '$2') // Remove any trailing zeroes
    : formattedCurrencyString
}

export default useNormalizedCurrency
